import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { VariantProps, cva } from 'class-variance-authority'
import { forwardRef } from 'react'

function ButtonV2(props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) {
  const {
    className,
    intent,
    fullWidth,
    size,
    pending,
    iconOnly,
    children,
    'aria-label': ariaLabel,
    ...rest
  } = props

  return (
    <button
      ref={ref}
      className={styles({ intent, className, size, pending, iconOnly, fullWidth })}
      aria-label={ariaLabel}
      {...rest}
    >
      {pending && <FontAwesomeIcon icon={faSpinnerThird} spin className="text-base" />}
      {children}
    </button>
  )
}

export default forwardRef(ButtonV2)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof styles> {}

const styles = cva(
  [
    'relative flex items-center justify-center rounded-[24px] gap-2 cursor-pointer border',
    'tracking-[0.4px] font-medium whitespace-nowrap',
    'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-1 focus-visible:ring-offset-white',
    'active:scale-[.96] transition-all',
    'disabled:text-[#8B8B8B] disabled:cursor-not-allowed disabled:shadow-none disabled:active:scale-[1]',
  ],
  {
    variants: {
      intent: {
        'primary-contained':
          'bg-app-orange-base hover:bg-app-orange-dark text-white border-transparent focus:ring-app-orange-base/30 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.08)] disabled:bg-[#E0E0E0]',
        'primary-outlined':
          'bg-white hover:bg-app-orange-light text-app-orange-base border-app-orange-base focus:ring-app-orange-base/30 disabled:border-[#E0E0E0]',
        'primary-text':
          'bg-transparent border-transparent hover:bg-app-orange-light text-app-orange-base focus:ring-app-orange-base/30',

        'secondary-contained':
          'bg-app-blue-base hover:bg-app-blue-dark text-white border-transparent focus:ring-app-blue-base/30 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.08)] disabled:bg-[#E0E0E0]',
        'secondary-outlined':
          'bg-[#F2F9FF] hover:bg-[#D6EBFF] text-app-blue-base border-app-blue-base focus:ring-app-blue-base/30 disabled:border-[#E0E0E0] disabled:hover:bg-[#F2F9FF]',
        'secondary-text':
          'bg-transparent border-transparent hover:bg-[#D6EBFF] text-app-blue-base focus:ring-app-blue-base/30',

        'purple-contained':
          'bg-app-purple-base hover:bg-app-purple-dark text-white border-transparent focus:ring-app-purple-base/30 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.08)] disabled:bg-[#E0E0E0]',
        'purple-outlined':
          'bg-[#EFEFFA] hover:bg-[#E0DFF5] text-app-purple-base border-app-purple-base focus:ring-app-purple-base/30 disabled:border-[#E0E0E0]',
        'purple-text':
          'bg-transparent border-transparent hover:bg-app-purple-light text-app-purple-base focus:ring-app-purple-base/30',

        'error-contained':
          'bg-danger-base hover:bg-danger-hover text-white border-transparent focus:ring-danger-base/30 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.08)] disabled:bg-[#E0E0E0]',
        'error-outlined':
          'bg-[#FFF4F6] hover:bg-danger-light text-danger-base border-danger-base focus:ring-danger-base/30 disabled:border-[#E0E0E0]',
        'error-text':
          'bg-transparent border-transparent hover:bg-danger-light text-danger-base focus:ring-danger-base/30',

        'warning-contained':
          'bg-warning-base hover:bg-warning-hover text-white border-transparent focus:ring-warning-base/30 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.08)] disabled:bg-[#E0E0E0]',
        'warning-outlined':
          'bg-white hover:bg-warning-light text-warning-base border-warning-base focus:ring-warning-base/30 disabled:border-[#E0E0E0]',
        'warning-text':
          'bg-transparent border-transparent hover:bg-warning-light text-warning-base focus:ring-warning-base/30',

        'info-contained':
          'bg-info-base hover:bg-info-hover text-white border-transparent focus:ring-info-base/30 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.08)] disabled:bg-[#E0E0E0]',
        'info-outlined':
          'bg-info-light/40 hover:bg-info-light text-info-base border-info-base focus:ring-info-base/30 disabled:border-[#E0E0E0] disabled:hover:bg-white disabled:opacity-70',
        'info-text':
          'bg-transparent border-transparent hover:bg-info-light text-info-base focus:ring-info-base/30',

        'success-contained':
          'bg-success-base hover:bg-success-hover text-white border-transparent focus:ring-success-base/30 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.08)] disabled:bg-[#E0E0E0]',
        'success-outlined':
          'bg-[#EFFCE1] hover:bg-success-light text-success-base border-success-base focus:ring-success-base/30 disabled:border-[#E0E0E0]',
        'success-text':
          'bg-transparent border-transparent hover:bg-success-light text-success-base focus:ring-success-base/30',

        'gray-contained':
          'bg-[#323232] hover:bg-[#CECECE] text-white border-transparent focus:ring-[#323232]/30 shadow-[0px_4px_8px_rgba(0,_0,_0,_0.08)] disabled:bg-[#E0E0E0]',
        'gray-outlined':
          'bg-white hover:bg-[#F5F5F5] text-[#323232] border-[#323232] focus:ring-[#323232]/30 disabled:border-[#E0E0E0]',
        'gray-text':
          'bg-[#EFF0F1] border-transparent hover:bg-[#F5F5F5] text-[#323232] focus:ring-[#323232]/30',
        'gray-text-fixed':
          'bg-transparent border-transparent hover:bg-[#F5F5F5] text-[#323232] focus:ring-[#323232]/30',

        'opaque-contained':
          'bg-[rgba(0,0,0,0.15)] hover:bg-[rgba(0,0,0,0.3)] text-white border-transparent focus:ring-[rgba(0,0,0,0.1)]/30 disabled:bg-[#E0E0E0]',
      },
      fullWidth: {
        true: 'w-full',
      },
      size: {
        'x-small': 'text-[13px] px-[7.5px] py-[3.5px]',
        small: 'text-[13px] px-[9.5px] py-[4.25px]',
        medium: 'text-sm px-4 py-[7px]',
        large: 'text-[15px] px-[21px] py-[9px]',
      },
      pending: {
        true: 'cursor-wait',
      },
      iconOnly: {
        true: '',
      },
    },
    compoundVariants: [
      { size: 'x-small', iconOnly: true, className: 'rounded-full h-[24px] w-[24px]' },
      { size: 'small', iconOnly: true, className: 'rounded-full h-[34px] w-[34px]' },
      { size: 'medium', iconOnly: true, className: 'rounded-full h-[48px] w-[48px]' },
      { size: 'large', iconOnly: true, className: 'rounded-full h-[59px] w-[59px] text-xl' },
    ],
    defaultVariants: {
      intent: 'primary-contained',
      size: 'medium',
    },
  }
)
